<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <!-- 日期选择框 -->
          <!-- <el-form-item label="时间">
            <el-date-picker
              @change="handleChangeDate"
              v-model="datePickerVal"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              default-value=""
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="城市">
            <el-input
              style="width: 170px"
              v-model="searchInfo.city"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 右侧查询 -->
          <el-form-item class="right-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="businessesTableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in businessesTableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div>
        <Pagination
          :total="dataTotal"
          :page="searchInfo.page"
          :limit="searchInfo.limit"
          @pagination="onChangePages"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'
// import { getDataAdminTabDataFun } from '../../../api/dataadmin'
// import axios from "axios";
import { getMerchantKanban, getBusinessesList } from "../../../api/common";

export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      // 日期选择框绑定值
      datePickerVal: [],
      // 查询数据
      searchInfo: {
        startTime: "",
        endTime: "",
        city: "",
        // page: 1,
        // size: 10,
      },
      // 表格数据
      invitationTableData: [], //邀约
      businessesTableData: [], //商户
      fundTableData: [], //资金
      // 表格标题数组
      businessesTableTitle: [
        //商户
        { name: "姓名", code: "name", width: "" },
        { name: "所在城市", code: "city", width: "" },
        // { name: "工单上限量", code: "ticketCap", width: "" },
        { name: "已下发工单数", code: "issue", width: "" },
        { name: "申诉成功", code: "appealSucceed", width: "" },
        { name: "剩余工单数", code: "ticketsNumber", width: "" },
        { name: "已成交", code: "turnover", width: "" },
        // { name: "成交转化率", code: "conversionRate", width: "" },
      ],
      // 数据总数
      dataTotal: 100,
    };
  },
  mounted() {
    this.onGetDataList(this.searchInfo.city);
  },
  methods: {
    // 修改日期
    handleChangeDate(date) {
      this.searchInfo.startTime = date[0];
      this.searchInfo.endTime = date[1];
    },
    // 查询
    onSearch() {
      this.onGetDataList(this.searchInfo.city);
    },
    // 重置
    onReset() {
      this.searchInfo.startTime = "";
      this.searchInfo.endTime = "";
      this.searchInfo.city = "";
      this.datePickerVal = "";
      this.onSearch();
    },
    // 核心查询数据方法
    onGetDataList(city) {
      // 查询数据方法
      console.log("=====" + JSON.stringify(city));
      if (city) {
        getMerchantKanban(city).then((res) => {
          console.log("res-----------------" + res);
          this.businessesTableData = res.data.queryResult.list;
          this.businessesTableData.forEach((item) => {
            item.ticketsNumber = item.setMeal - item.turnover;
            item.ticketCap = item.set_Meal - item.turnover;
            const num = ((item.clean / item.gross) * 100).toFixed(2) + "%";
            item.conversionRate = num;
            item.appealSucceed = item.appealSucceed ? item.appealSucceed : "0";
            item.turnover = item.turnover ? item.turnover : "0";
          });
        });
      } else {
        getBusinessesList().then((res) => {
          this.businessesTableData = res.data.queryResult.list;
          this.businessesTableData.forEach((item) => {
            item.phone = item.phone
              ? item.phone.slice(0, 3) + "****" + item.phone.slice(7, 11)
              : "——";
            item.registrationTime = item.registrationTime
              ? item.registrationTime.slice(0, 7)
              : "——";
            item.appeal = item.appeal == null ? "0" : item.appeal;
            item.appealSucceed =
              item.appealSucceed == null ? "0" : item.appealSucceed;
            item.turnover = item.turnover == null ? "0" : item.turnover;
            item.ticketsNumber = item.setMeal - item.issue;
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
</style>
